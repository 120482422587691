import { Col, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

interface Props {
	title: string;
	path: string;
}

export function ViewAndDownload(props: Props) {
	return (
		<Row>
			<Col>{props.title}</Col>
			<Col xs={1}>
				<a
					target="_blank"
					href={props.path}
					rel="noreferrer"
				>
					<Icon.EyeFill />
				</a>
			</Col>
			<Col xs={1}>
				<a
					download
					href={props.path}
				>
					<Icon.Download />
				</a>
			</Col>
		</Row>
	);
}
