import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ViewAndDownload } from "../Modules/Helper";
import { Helmet } from "react-helmet";

export default function Legal() {
	return (
		<>
			<Helmet>
				<title>{"EarsOnly | Legal"}</title>
			</Helmet>

		<Container className="container-padding-top container-padding-bottom">
			<h1>Wet- en regelgeving</h1>
			<h3>Nederlands</h3>
			<hr />

			<ViewAndDownload
				title="Privacyverklaring"
				path="/documents/legal/Nederlands/EarsOnlyPrivacyverklaringNL.pdf"
			/>
			<ViewAndDownload
				title="Declaration of Conformity"
				path="/documents/legal/Nederlands/SPE-DP011-003_v1.0_DoC_EU_DECLARATION_OF_CONFORMITY-NL.pdf"
			/>
			<ViewAndDownload
				title="Algemene Voorwaarden (Weder verkoop)"
				path="/documents/legal/Nederlands/EarsOnly_ALGEMENE_VOORWAARDEN_Wederverkoop_NL.pdf"
			/>

			<br />
			<br />
			<br />
			<h3>English</h3>
			<hr />

			<ViewAndDownload
				title="Privacy Policy"
				path="/documents/legal/English/MyEarsOnlyPrivacyPolicy.pdf"
			/>
			<ViewAndDownload
				title="Declaration of Conformity"
				path="/documents/legal/English/SPE-DP011-003_v1.0_DoC_ EU_DECLARATION_OF_CONFORMITY-EN.pdf"
			/>
			<ViewAndDownload
				title="Terms of service (Resale)"
				path="/documents/legal/English/EarsOnly_GENERAL_TERMS-Resale_EN.pdf"
			/>
		</Container>
		</>
	);
}
