import { Button, Container, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { Helmet } from "react-helmet";

export default function PageDoesNotExist() {
	return (
		<Container className="container-padding-top page-not-found">
						<Helmet>
				<title>{"EarsOnly | 404"}</title>
			</Helmet>

			<h1>404 Error</h1>
			<h2>Page not found</h2>
			<br />
			<br />
			<br />
			<br />
			<Button
				className="fw-bold"
				href="/home"
				size="lg"
			>
				<Icon.CaretLeftFill /> Go to the homepage
			</Button>
		</Container>
	);
}
