import { Container, Image, Nav, Navbar, Row, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export default function NavigationBar() {
	return (
		<>
			<Navbar
				expand="lg"
				className="bg-light navigation-brand"
				fixed="top"
			>
				<Container>
					<Navbar.Brand href="/home">
						<Image
							src="/images/EarsOnly_Logo_FullColour_Grey.svg"
							alt="EarsOnly logo"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Container >
							<Nav className="nav-container">
								<Row >
									{/* <Col> <Nav.Link href="/home">Home</Nav.Link> </Col> */}
									<Col>
										<Nav.Link href="/protect">Protect</Nav.Link>
									</Col>
									<Col>
										<Nav.Link href="/faq">Support</Nav.Link>
									</Col>
									<Col>
										<Nav.Link href="/contact">Contact</Nav.Link>{" "}
									</Col>
									<Col>
										<Nav.Link
											target="_blank"
											href="https://earsonly-portal.dopple.site"
											className="navigation-portal"
										>
											Portal 
										</Nav.Link>{" "}
									</Col>
								</Row>
							</Nav>
						</Container>
					</Navbar.Collapse>
					{/* <Col> <Nav.Link href="#contact">Contact</Nav.Link> </Col> */}
				</Container>
			</Navbar>
		</>
	);
}
