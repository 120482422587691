import * as React from "react";
import { Button, Col, Figure, Image, Row } from "react-bootstrap";

export default function ImageHighlights() {
	return (
		<>
			<Row
				xs={1}
				sm={2}
				className="highlight"
			>
				<Col className="image-highlight-text">
					<h5>Dynamische demping</h5>
					Met dynamische demping in elke Earbud blijf je je bewust van je omgeving terwijl je gehoor wordt beschermd.
				</Col>
				<Row
					xs={1}
					md={3}
					className="animation-row"
				>
					<Image
						src="images/gehoorbescherming/focus.svg"
						className="d-none d-md-block "
						alt="Gehoorbescherming modus focus"
					></Image>
					<Image
						src="images/gehoorbescherming/dynamisch.svg"
						className="d-none d-md-block "
						alt="Gehoorbescherming modus dynamisch"
					></Image>
					<Image
						src="images/gehoorbescherming/communicatie.svg"
						className="d-none d-md-block "
						alt="Gehoorbescherming modus communicatie"
					></Image>
					<Image
						src="images/gehoorbescherming/focus.svg"
						className="d-md-none gehoorbescherming focus"
						alt="Gehoorbescherming modus focus"
					></Image>
					<Image
						src="images/gehoorbescherming/dynamisch.svg"
						className="d-md-none gehoorbescherming dynamisch"
						alt="Gehoorbescherming modus dynamisch"
					></Image>
					<Image
						src="images/gehoorbescherming/communicatie.svg"
						className="d-md-none gehoorbescherming communicatie"
						alt="Gehoorbescherming modus communicatie"
					></Image>
				</Row>
			</Row>
			<Row
				xs={1}
				sm={3}
				className="highlight highlight-row"
			>
				<Col className="image-highlight-text">
					<h5>Muziek luisteren op veilige geluidsniveaus</h5>
					Geniet van muziek van hoge kwaliteit zonder dat dit ten koste gaat van uw gehoorbescherming.
				</Col>
				<Col className="image-highlight-text">
					<h5>Bone-conduction technologie </h5>
					<br className="bone-br"/>
					Telefoneer in luidruchtige omgevingen dankzij de geïntegreerde Bone-conduction technologie.
				</Col>
				<Col className="image-highlight-text">
					<h5>Unieke pasvorm met persoonlijke instellingen</h5>
					Op maat gemaakte pasvorm zorgt voor maximaal comfort en geluidsdemping. Persoonlijke instellingen kunnen met een mobiele app worden aangepast.
				</Col>
			</Row>
		</>
	);
}

