import * as React from "react";
import { Col, Row, Image } from "react-bootstrap";

export default function Product() {
	return (
		<>
			<Row
				xs={1}
				md={2}
				className="cradle-hero "
			>
				<Col className=" d-md-none product-animation-row">
					<Image
						className="product-animation prod-1"
						src="images/cradle_and_buds/cradle_2_v2.png"
						alt="product animatie afbeelding gesloten Cradle"
					/>
					<Image
						className="product-animation prod-2"
						src="images/cradle_and_buds/cradle_1_v2.png"
						alt="product animatie afbeelding open Cradle"
					/>
					<Image
						className="product-animation prod-3"
						src="images/cradle_and_buds/cradle_3_v2.png"
						alt="product animatie afbeelding open cradle een Earbud uit de Cradle"
					/>
				</Col>
				<Col>
					<h1>
						EarsOnly <br />
						Protect
					</h1>
					<h4>
						True-Wireless op maat gemaakte gehoorbescherming met dynamische demping. Communiceren in luidruchtige omgevingen en genieten van hoge kwaliteit muziek op veilige
						geluidsniveaus. <b> Altijd beschermd.</b>
					</h4>
				</Col>
				<Col className=" d-none d-md-block product-animation-row">
					<Image
						className="product-animation prod-1"
						src="images/cradle_and_buds/cradle_2_v2.png"
						alt="product animatie afbeelding gesloten Cradle"
					/>
					<Image
						className="product-animation prod-2"
						src="images/cradle_and_buds/cradle_1_v2.png"
						alt="product animatie afbeelding open Cradle"
					/>
					<Image
						className="product-animation prod-3"
						src="images/cradle_and_buds/cradle_3_v2.png"
						alt="product animatie afbeelding open cradle een Earbud uit de Cradle"
					/>
				</Col>
			</Row>
		</>
	);
}
