import * as React from "react";
import { Row, Col, Image } from "react-bootstrap";

export default function AppShowcase() {
	return (
		<>
			<Row
				sm={1}
				lg={3}
				className="appshowcase"
			>
				<Col lg={4} className="app-information">
					<p>
						<b>My EarsOnly</b>
						<br />
						Met de My EarsOnly companion-app kunt u niet alleen uw gehoorbescherming nauwkeurig afstemmen op uw omgeving, maar krijgt u ook de mogelijkheid om uw luisterervaring te
						personaliseren zonder concessies te doen aan de veiligheid.
					</p>
				</Col>
				<Col lg={4}>
					<Image src="images/app.png" />
				</Col>
				<Col lg={4} className="">
					<Row>
						<p>
							<b>Beheer</b> je gehoor-beschermingsinstellingen
						</p>
					</Row>
					<Row>
						<p>
							<b>Configureer</b> je Earbud interacties
						</p>
					</Row>
					<Row>
						<p>
							<b>Selecteer</b> je favoriete muziek luisterervaring
						</p>
					</Row>
				</Col>
			</Row>
		</>
	);
}
