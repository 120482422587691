import { Col, Container, Image, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
export default function Footer() {
	return (
		<footer className="footer">
			<Row
				xs={2}
				md={4}
				className=" justify-content-md-center gx-0"
			>
				<Col>
					<p className="footer-bold">Wet- en regelgeving</p>
					<a href="/legal">Declaration of conformity</a> <br />
					<a href="/terms">Algemene voorwaarden</a> <br />
					<a href="/privacy">Privacyverklaring</a> <br />
				</Col>
				<Col>
					<p className="footer-bold">Bezoek</p>
					<address>
						Overcingellaan 7 <br />
						9401 LA Assen <br />
						Nederland
					</address>
				</Col>
			</Row>
			<Row className="gx-0">
				{" "}
				<Col className="footer-earsonly ">&copy; EarsOnly 2024</Col>
			</Row>
		</footer>
	);
}
