import React, { useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
// import Home from "./Routes/Home";
import PageDoesNotExist from "./Routes/PageDoesNotExist";
import NavigationBar from "./Modules/NavigationBar";
import Footer from "./Modules/Footer";
import Protect from "./Routes/Protect";
import FAQ from "./Routes/FAQ";
import Legal from "./Routes/Legal";
import Contact from "./Routes/Contact";
import ReactGA from "react-ga4";

function App() {
	useEffect(function InitializeGoogleAnalytics() {
		ReactGA.initialize("G-0K634NB6BX");
	}, []);

	return (
		<>
			<NavigationBar />
			<BrowserRouter>
				<Routes >
					<Route
						path="/"
						element={<Outlet />}
					>
						<Route
							path=""
							element={<Protect />}
						/>
						{/* <Route path="home" element={<Home />} /> */}
						<Route
							path="home"
							element={<Protect />}
						/>
						<Route
							path="protect"
							element={<Protect />}
						/>

						<Route
							path="faq"
							element={<FAQ />}
						/>
						<Route
							path="manuals"
							element={<FAQ />}
						/>
						<Route
							path="support/faq"
							element={<FAQ />}
						/>

						<Route
							path="legal"
							element={<Legal />}
						/>
						<Route
							path="conformity"
							element={<Legal />}
						/>
						<Route
							path="terms"
							element={<Legal />}
						/>
						<Route
							path="privacy"
							element={<Legal />}
						/>

						<Route
							path="contact"
							element={<Contact />}
						/>
						<Route
							path="*"
							element={<PageDoesNotExist />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
			<Footer />
		</>
	);
}

export default App;
