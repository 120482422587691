import * as React from "react";
import { Col, Figure, Image, Row } from "react-bootstrap";

export default function Highlights() {
	interface Props {
		title: string;
		source: string;
	}

	function HighlightIcon(icon: Props) {
		return (
			<Row>
				<Col
					xs={4}
					sm={2}
				>
					<Image
						className="icon-icon"
						alt={`${icon.title} pictogram`}
						src={`${icon.source}`}
					/>
				</Col>
				<Col
					xs={8}
					className=" icon-text"
				>
					{icon.title}
				</Col>
			</Row>
		);
	}

	return (
		<>
			<Row
				className="highlight-icons "
				xs={2}
				md={2}
				lg={3}
			>
				<HighlightIcon
					title="Dynamische bescherming"
					source="images/icons/ear-protect-icon.svg"
				/>

				<HighlightIcon
					title="Veilige communicatie"
					source="images/icons/groupchat-icon.svg"
				/>
				<HighlightIcon
					title="HQ Muziek luisteren"
					source="images/icons/music-listening-icon.svg"
				/>

				<HighlightIcon
					title="Handsfree bellen"
					source="images/icons/call-environment-icon.svg"
				/>

				<HighlightIcon
					title="Multi-Point verbinding"
					source="images/icons/mobile-laptop-icon.svg"
				/>
				<HighlightIcon
					title="Mobiele App ondersteuning"
					source="images/icons/android-apple-icon.svg"
				/>

				<HighlightIcon
					title="IP57 Stof- & Waterbestendig"
					source="images/icons/ip57-icon.svg"
				/>

				<HighlightIcon
					title="USB & Draadloos laden"
					source="images/icons/battery-charging-icon.svg"
				/>

				<HighlightIcon
					title="Bluetooth® True Wireless technologie"
					source="images/icons/bluetooth-logo-icon.svg"
				/>

				<Row></Row>
			</Row>{" "}
			<Row>
				<h2 className="large-image-title">
					De eerste op-maat-gemaakte, <br /> True-Wireless gehoorbescherming
				</h2>
				<p className="certificering">
					<b>Gecertificeerd voor:</b> <br />
					EN-352-2 <br />
					EN-352-7 <br />
					EN-352-9 <br />
					EN-352-10 <br />
					ANSI S12.42 IPIL
				</p>
				<Col>
					<Image
						className="large-image"
						src="images/hardhat_4.jpg"
						alt="EarBuds voor een bouwhelm"
					/>
				</Col>
			</Row>
		</>
	);
}
