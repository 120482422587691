import * as React from "react";
import { Image, Col, Row, Button } from "react-bootstrap";
export default function Process() {
	return (
		<>
			<h1 className="proces-titel">Ons proces</h1>
			<Row
				className="process"
				xs={1}
				sm={2}
				md={2}
				lg={4}
			>
				<Col className="process-block">
					<Image
						src="images/icons/call-orange.svg"
						alt="Maak een afspraak pictogram"
					/>
					<Row>
						<Col xs={2}>
							<h2>1.</h2>
						</Col>
						<Col>
							<h5>Neem contact op met een dealer</h5>{" "}
						</Col>
						<p>
							Maak eenvoudig een afspraak bij een van onze resellers.
							<br />
							<Button
								className="button"
								href="/contact"
							>
								Contact
							</Button>
						</p>
					</Row>
				</Col>

				<Col className="process-block">
					<Image
						src="images/icons/measure-orange.svg"
						alt="Oormeting pictogram"
					/>
					<Row>
						<Col xs={2}>
							<h2>2.</h2>
						</Col>
						<Col>
							<h5>Metingen op locatie </h5>
						</Col>
					</Row>
					<p>Tijdens de geplande afspraak komen we bij u langs om persoonlijke aanmetingen te doen. </p>
				</Col>

				<Col className="process-block">
					<Image
						src="images/icons/make-orange.svg"
						alt="Maak het product pictogram"
					/>
					<Row>
						<Col xs={2}>
							<h2>3.</h2>
						</Col>
						<Col>
							<h5>Modelleren en printen</h5>
						</Col>
						<p>Met onze unieke 3D-scan en printtechnieken maken wij jouw op-maat-gemaakte Earbuds.</p>
					</Row>
				</Col>

				<Col className="process-block">
					<Image
						src="images/icons/use-orange.svg"
						alt="Gebruik EarBuds pictogram"
					/>
					<Row>
						<Col xs={2}>
							<h2>4.</h2>
						</Col>
						<Col>
							<h5>Levering van uw Earbuds</h5>
						</Col>
						<p>Wij komen uw Earbuds persoonlijk afleveren, geven uitleg en nemen een lektest af.</p>
					</Row>
				</Col>
			</Row>
		</>
	);
}
