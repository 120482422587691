import * as React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
export default function Testimonials() {
	interface Props {
		name: string;
		function: string;
		quote: string;
		image: string;
	}
	function Testimonial(props: Props) {
		return (
			<Row
				sm={1}
				lg={2}
			>
				<Col className="testimonial">
					<Col>
						<p className="testimonial-quote">"{props.quote}"</p>
						<p>
							<Row>
								<Col>
									<b> {props.name}</b>
									<br />
									{props.function}
								</Col>
							</Row>
						</p>
					</Col>
				</Col>
				<Col className="testimonial-image-col">
					<Image
						src={props.image}
						className="testimonial-image"
						alt="Persoon met EarBud in oor"
					/>
				</Col>
			</Row>
		);
	}

	return (
		<>
			<br />
			<Row >
				<Swiper
					modules={[Pagination, Autoplay]}
					spaceBetween={200}
					slidesPerView={1}
					autoplay={{ pauseOnMouseEnter: true, delay: 10000 }}
					pagination={{ clickable: true, type: "bullets" }}
				loop={true}
				>
					<SwiperSlide>
						<Testimonial
							name="Arnold Leemhuis"
							function="Timmerman bij Roald Hans"
							quote="Wat een geweldige oortjes, wat een mooi product. Ze zitten super fijn, top geluid. Heel blij mee! Bedankt"
							image="images/testimonials/testimonial-arnold.png"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Testimonial
							name="Lars "
							function="Technische Dienst bij Hoekman Houtindustrie"
							quote="Ik wil nooit meer zonder!"
							image="images/testimonials/testimonial-lars.png"
						/>
					</SwiperSlide>
				</Swiper>
			</Row>
		</>
	);
}
