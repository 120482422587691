import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Image } from "react-bootstrap";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

export default function Map() {
	const locationOffice: LatLngExpression = [52.99014, 6.56922];

	var Icon = L.icon({
		iconUrl: "images/orange-marker2.png",
		iconSize: [50, 50], // size of the icon
		iconAnchor: [25, 50], // point of the icon which will correspond to marker's location
	});
	return (
		<>
			<MapContainer
				center={locationOffice}
				zoom={11}
				zoomControl={false}
				
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker
					position={locationOffice}
					icon={Icon}
				>
					<Image src="/images/EarsOnly_Logo_FullColour_Grey.svg" />
				</Marker>
			</MapContainer>
		</>
	);
}
